<template>
<div class="result-item result-expanded mb-3" :style="cssProps" :id="'vehicle-'+item.selectedVehicle.id">

    <div class="first-row row m-0 w-100">
      <div class="w-100 flex-column flex-xl-row d-xl-flex text-center align-items-center">
        <div class="col-image">
          <img  :src="item.selectedVehicle.image || item.selectedVehicle.thumbnail" class="vehicle-image">
        </div>
        <div class="col-info">
          <h3 class="title">{{ item.selectedVehicle.title || item.selectedVehicle.name }}</h3>
          <div class="configurations-wrapper" v-if="(item.selectedVehicle.configurations.length > 0 || item.selectedVehicle.air_conditioning || item.selectedVehicle.bathroom)">
            <p class="font-weight-bold">Configurações:</p>
            <p class="configurations">
              <span v-if="item.selectedVehicle.air_conditioning">
                Ar condicionado
              </span>
              <span v-if="item.selectedVehicle.bathroom">
                Banheiro
              </span>
              
              <span v-for="(config, index) in item.selectedVehicle.configurations" :key="index">
                {{config}}
              </span>
            </p>
          </div>
        </div>

        <div class="col-price text-right">
          <button @click.prevent="removeCartItem" class="btn btn-block remove mb-2">REMOVER DO ORÇAMENTO</button>

          <div class="price w-100">
            <span class="value">{{ ( this.calculateTotals()) | currency }}</span>
          </div>

        </div>
        
      </div>
    </div>

    
    <trip-address :vehicle="item" route="checkout" v-if="!item.selectedVehicle.usingTwoTrips"/>
    <split-trip-address v-else :vehicle="item"/>

    <div class="row row-trip-info w-100" v-if="getStopPoints && getStopPoints.length > 0">
      <div class="col-md-12">
        <div class="usage-item align-items-start d-flex mb-4 p-4 w-100 flex-column">
          <h4 class="trip-info-title">Outros pontos de embarque ou desembarque</h4>
          <div v-for="(stop, index) in getStopPoints" :key="'stop' + index">
            <b-icon-geo-alt></b-icon-geo-alt> Ponto {{index +1}} : {{stop.address}}
          </div>
        </div>
      </div>
    </div>
    
    <div class="row row-trip-info w-100" v-if="hasDriverExpenses && !item.selectedVehicle.usingTwoTrips">
      <div class="col-12 mt-2">
        <h4 class="trip-info-title w-100 d-flex justify-content-between">
          <div class="title">
            Despesas com motorista
          </div>
         </h4>
         <div class="usage-item align-items-center row mb-4 w-100">
          <div class="col-lg-9 col-12">
            {{ this.getDriverExpensesTitle() }}
          </div>
          <div class="col-lg-3 col-12 d-flex align-items-lg-end align-items-start mt-2 mt-lg-0">
            {{ (driverExpensesValue) | currency }}
          </div>
         </div>
      </div>
    </div>

    <div class="row row-trip-info w-100" v-if="item.observations">
      <div  class="observations-card col-md-12">
        <h5 class="trip-info-title">Observações</h5>
        <p class="mb-3">{{item.observations}}</p>
      </div>
    </div>
    
  </div>
</template>

<script>

import TripAddress from '../../components/search-results/TripDetails/TripAddress.vue'
import SplitTripAddress from '../../components/search-results/TripDetails/SplitTripAddress.vue'
import axios from 'axios'

export default {
  name: 'TripItem',
  props: ['item', 'index'],
  data () {
    return {
      isVisible: true,
      visible: true,
      gallery: [],
      img: '',
      indexImg: 0,
      driverExpensesTitle: '', //this.getDriverExpensesTitle()
    }
  },
  computed: {
    getStopPoints () {
      return this.item.stop_points
    },
    driverExpensesValue() {
      return this.item.tripDetails ? this.item.tripDetails.driverExpenses.driverExpensesValue : this.item.driverExpenses.driverExpensesValue
    },
    driverExpensesObject() {
      return this.item.tripDetails ? this.item.tripDetails.driverExpenses : this.item.driverExpenses
    },
    hasDriverExpenses () {
      var status = this.item.driverExpenses && this.item.driverExpenses.enable;
      return status || this.item.tripDetails && this.item.tripDetails.driverExpenses.driverExpensesValue > 0;
    },
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.primaryColor
    },
    secondaryColor () {
      return this.$store.state.companyDetails.whitelabel.secondaryColor
    }
  },
  components: { TripAddress, SplitTripAddress },
  methods: {
    usageVehicleType(type) {
      switch (type) {
        case 'onibus':
          return 'onibus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro_onibus'
        case 'carro' :
          return 'carro'
        default:
          return 'onibus'
      }
    },
    
    calculateTotals() {
      let total = 0;
      let $scope = this;
      total += this.item.selectedVehicle.total ? this.item.selectedVehicle.total : this.item.selectedVehicle.priceCents;
      return total;

    },
    getDriverExpensesTitle() {
      var driverExpenses = this.driverExpensesObject
      return driverExpenses.payDriverExpenses === 'paynow' ?
      'Quero pagar agora e não me preocupar com isso durante a viagem' :
      'Me responsabilizo por providenciar e pagar durante a viagem';
    },
    getUsageVehiclePrice(vehicle, quote){
      let usagePrice = this.$store.state.companyDetails.vehicleUsageQuotes[quote][this.usageVehicleType(vehicle.selectedVehicle.type)] * parseInt(vehicle.selectedVehicle.factor_usage);
      return usagePrice;
    },
    usageVehicleType(type) {
      switch (type) {
        case 'onibus':
          return 'onibus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro_onibus'
        case 'carro' :
          return 'carro'
        default:
          return 'onibus'
      }
    },
    async removeCartItem () {
      this.$emit('reload');
      this.$store.commit('cart/removeItem', this.index)
      this.$store.commit("cart/calculateCartTotal", {data: this.$store.state});

      if(this.$store.state.cart.cartData.items.length == 0){
        this.$store.commit('cart/setClearCart', false)
        this.$store.commit('quote/resetQuoteItems', false)
      }
    },
    showVehicleDetails () {
      this.isVisible = true
    },
    hideVehicleDetails () {
      this.isVisible = false
    },
    show () {
      this.visible = true
    },
    handleHide () {
      this.visible = false
    },
    showSingle (index) {
      this.img = this.gallery[index]
      this.show()
    }
  },
  mounted () {
   
  }
}
</script>

<style lang="scss" scoped>

  @import "../../assets/scss/components/vehicle-card.scss";

  .usage-item {

    padding: 15px 10px;
    border-radius: 5px;
    margin: 0;
    margin-bottom: 0px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    .trip-info {
      margin-bottom: 10px;

      svg {
        margin-right: 15px;
      }

    }

  }
  .observations-card {
    p {
      padding: 20px;
      background: #e5e5e5;
      border-radius: 5px;
      color: black;
    }
  }

  .trip-info{
    text-transform: capitalize;
  }
  .trip-item-card {
    border: none!important;
    background-color: #ffffff;

    .checkout-table {
      padding: 10px 30px 30px!important;
      border-top: 1px solid #e5e5e5;

      .row {
        justify-content: space-between;
        margin-bottom: 10px;
        border-bottom: 1px solid #e5e5e5;
        color: black;
      }

    }

    hr {
      margin: 0!important;

      &:last-child {
        display: none!important;
      }
    }

    .card-info {
      padding: 30px 30px 0;

      @media (max-width: 980px) {
        padding: 20px 20px 0;
      }

      .type-tag {
          position: absolute;
          left: 0;
          z-index: 99;
          background: var(--default-color);
          color: white;
          padding: 5px 40px;
          top: 20px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
      }

      
    }

    .row-actions {
      .col-actions {
        .book-button {
            border-color: var(--default-color);
            color: white!important;
            background-color: var(--default-color);
            border-radius: 4px;
        }
      }
    }

    .first-row {
      padding: 30px 40px;
      @media (max-width: 1024px) {
        padding: 15px;
      }
    }

    .row-stop-points {
      .col-md-6 {
        
      }
    }
    .row-trip-info {
      background: #fff;
      margin: 0;
      padding: 30px 30px 0;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;

      &:last-child {
        border: none!important;
      }
      .trip-info {
          font-size: 16px;
          margin-bottom: 10px;
           color: black!important;

          * {
            color: black!important;
          }

          svg {
              margin-right: 15px!important;
          }
      }

      h4 {
        font-weight: 500!important;
      }
    }
    .col-info {
     .title {
        .type {
            color: #0558ff;
            font-size: 18px;
            width: 100%;
            display: block;
            opacity: .7;
        }
      }
      .configurations-wrapper {
        padding-top: 5px;

        span {
          background: transparent;
          color: #0d5bdc;
          margin-right: 5px;
          padding: 0 5px;
          margin-bottom: 5px;
          display: flex;
          border-color: #0d5bdc;
          border: 1px solid;
          border-radius: 5px;
        }
        p {
          text-align: left;
          margin-bottom: 0;
          font-size: 14px;
          color: #000;
          text-transform: capitalize;
          display: flex;
          flex-wrap: wrap;
        }
        .configurations {
          padding-top: 10px;
        }
      }
    }
    .configurations {
      span {

      }
    }
    .col-price  {
      .pricePerSeat {
        span {
          font-size: 14px;
          font-weight: 500;
          text-align: center;
        }
      }
      .more-info  {
        a {
          background-color: var(--default-color);
          border-color: var(--default-color);
          color: white;
        }
      }
    }

    .item-image-wrapper {
      display: flex;
      align-items: flex-start;
      flex-flow: column;
      justify-content: center;
    }

    .item-title {
      margin-bottom: 20px;
    }

    .price {
      font-size: 22px;
      color: #293c4b;
      font-weight: bold;
      text-align: right;
      @media (max-width: 980px) {
        text-align: center;
      }
    }

    .card-body {
      padding: 0;
      box-shadow: 0px 1px 6px rgba(61,67,76,0.15);
    }

    .item-image {
      max-width: 100%;
      border-radius: 8px;
      margin-bottom: 15px;
    }
  }
</style>
