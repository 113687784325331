<template>
  <div v-if="isLoading" class="spinner-wrapper bg-white">
    <lottie-animation
        :width=300
        :height=300
        path="animation/freta-loading.json"
      />
  </div>
  <div v-else-if="budgets.length == 0" class="d-flex justify-content-center flex-column mb-5 text-center">
    <div class="empty-list">
      <img class="skyline-1" src="@/assets/img/empty-list.svg" alt="">
      <h4 class="mt-5">Você não salvou nenhum orçamento ainda!</h4>
    </div>
  </div>
  <div v-else class="budget-list"  style="">
    <div class="col-12 col-xxl-8 offset-xxl-2 p-0">
        <div class="row py-2 justify-content-center">
          <div class="col-md-12 item-order card" v-for="(budget) in budgets" v-bind:key="budget.id" :data-id="budget.id">
           
            <div class="budget-item" v-for="(item, index) in budget.items" :key="index" >
              <div>
                <order-trip-item
                searchTitle="Consultar disponibilidade"
                :quote="budget" template="list" :item="item" :index="index" :removeBudget="() => removeBudget(budget.id)" 
                :doSearch="() => checkAvailabilityApply(budget)" 
                :quotePDF="() => checkAvailabilityPrint(budget)"
                :checkout="() => checkAvailabilityCheckout(budget)"></order-trip-item>
              </div>            
<!--
            <trip-item-card template="list" v-if="shouldApplySearch(budget)" :showButtonDetail="true" :item="item" :index="index" searchTitle="Aplicar pesquisa" :removeBudget="() => removeBudget(budget.id)" :doSearch="() => applyBudget(item)"/>

              <trip-item-card v-else :showButtonDetail="true" :item="item" :index="index" :removeBudget="() => removeBudget(budget.id)" searchTitle="Ir para o checkout" :doSearch="() => goToCheckout(item, budget)"/> -->

            </div>
            
            <div class="col-12 quote-action-btns" v-if="budget.items && budget.items[0].type == 'diaria'">
                <div class="buttons col-12 d-flex">
                  <!-- <div class="btn btn-block btn-details" @click="applyBudget(budget)">
                    <span>Aplicar pesquisa</span>
                  </div>

                  <div class="btn btn-block btn-details btn-delete" @click="removeBudget(budget.id)">
                    <span>Remover pesquisa</span>
                  </div>

                  <div class="btn btn-block btn-details btn-delete" @click="print(budget)">
                    <span>Baixar orçamento</span>
                  </div> -->
                </div>
              </div>  
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import TripItemCard from '@/components/order-confirmation/TripItemCard.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import OrderTripItem from './OrderTripItem.vue'
import OrderTransferItem from './OrderTransferItem.vue'
import FileSaver from 'file-saver'

export default {
  name: 'Budget',
  components: {
    TripItemCard,
    LottieAnimation,
    OrderTripItem,
    OrderTransferItem
},
  data: function () {
    return {
      budgets: [],
      isLoading: true,
      paymentHighestDate: false,
      differenceDays: false,
      paymentHighestDateFormated: false,
      bank_slip_installments: [],
      available: false,
      actionSelected: '',
    }
  },
  methods: {
    shouldApplySearch (budget) {
      return this.moment().isAfter(this.moment(budget.quoteExpiryDate, 'DD/MM/YYYY'), 'days')
    },
    removeBudget (id) {
      axios.delete(process.env.API_URL + 'user/quotes/' + id)
        .then((response) => {
          if (response.status) {
            this.budgets = this.budgets.filter( (budget) => budget.id != id); 
            this.$swal({
              title: 'Sucesso!',
              text: 'Orçamento excluido',
              icon: 'success',
              timer: 2500,
              showConfirmButton: false,
              iconColor: this.$store.state.companyDetails.whitelabel.body_font_color
            })

            if (this.budgets.length === 0) {
              let myaccount = document.getElementById('my-account')
              myaccount.classList.add('empty-list')
            }
            
          } else {
            this.$swal('Erro!', 'Um erro ocorreu ao excluir o orçamento, contate o suporte', 'error')
          }
        })
    },
    getSearchType (type) {
      switch (type) {
        case 'viagem':
          return 'viagem'
          break;

        case 'transfer':
          return 'transfer'
          break;

        case 'diaria':
          return 'diaria'
          break;

        default:
          break;
      }
    },
    goToCheckout (budget) {
      let $scope = this
      budget.items.forEach((item) => {
          let tripData = {
            selectedVehicle: {
              ...item.vehicle,
              image: item.vehicle.thumbnail,
              title: item.vehicle.name,
            }, 
            type: item.type,
            origin: item.trip.origin,
            destination: item.trip.destination,
            tripDetails: item.trip,
            quote_id: budget.id,
            metas: item.metas
          }
          $scope.$store.commit('cart/setCartItem', tripData)
        })
      this.$store.commit("cart/calculateCartTotal", {data: this.$store.state});
      this.$router.push('/checkout')
    },
    getBudgets () {
      axios.get(process.env.API_URL + 'user/quotes').then((response) => {
        this.budgets = response.data.data
        this.isLoading = false

        if (this.budgets.length === 0) {
          let myaccount = document.getElementById('my-account')
          myaccount.classList.add('empty-list')
        }
      }).then(() => {
        this.cleanOldQuotedItems()

        if (this.budgets.length === 0) {
          let myaccount = document.getElementById('my-account')
          myaccount.classList.add('empty-list')
        }
      })
    },
    deleteBudget (id) {
      axios.delete(process.env.API_URL + `user/search/${id}`)
        .then(() => {
          this.getBudgets()
        })
    },
    checkAvailabilityApply (budget){
      this.$store.commit('SET_LOADING_STATE', {value: true, text: 'Aplicando pesquisa'})
      axios({
        method: 'GET',
        url: process.env.API_URL + `quotes/${budget.id}/check-availability`
      }).then(async (response) => {
          this.$store.commit('SET_LOADING_STATE', { value: false })
          this.available = response.data.available.all
          if(this.available){
            this.applyBudget(budget)
          }else{
            this.$swal({
            title: 'Consulte disponibilidade!',
            text: 'Este orçamento precisa ser fechado com um atendente.',
            showDenyButton: false,
            showConfirmButton: true,
            confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            icon: 'error'
          })
          this.actionSelected = ''
          }
        })
    },
    checkAvailabilityPrint(budget){
      axios({
        method: 'GET',
        url: process.env.API_URL + `quotes/${budget.id}/check-availability`
      }).then(async (response) => {
          this.available = response.data.available.all
          if(this.available){
            this.print(budget)
          }else{
            this.$swal({
            title: 'Consulte disponibilidade!',
            timer: 4500,
            text: 'Este orçamento precisa ser fechado com um atendente.',
            showDenyButton: false,
            showConfirmButton: true,
            confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            icon: 'error'
          })
          this.actionSelected = ''
          }
        })
    },
    checkAvailabilityCheckout(budget){
      this.$store.commit('SET_LOADING_STATE', {value: true, text: 'Adicionando veículo ao orçamento'})
      axios({
        method: 'GET',
        url: process.env.API_URL + `quotes/${budget.id}/check-availability`
      }).then(async (response) => {
          this.$store.commit('SET_LOADING_STATE', { value: false })
          this.available = response.data.available.all
          if(this.available){
            this.$store.commit('cart/defineCartSource', response.data.source ? response.data.source :  'whitelabel');
            this.goToCheckout(budget)
          }else{
            this.$swal({
            title: 'Consulte disponibilidade!',
            timer: 4500,
            text: 'Este orçamento precisa ser fechado com um atendente.',
            showDenyButton: false,
            showConfirmButton: true,
            confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            icon: 'error'
          })
          this.actionSelected = ''
          }
        })
    },
   async applyBudget (budget) {
      let searchType = ''
      if(budget.items != undefined){
        searchType = this.getSearchType(budget.items[0].type)
      }else{
        searchType = this.getSearchType(budget.type)
      }
      this.$store.commit('RESET_SEARCH_FORM_FIELDS', 'trip')
      if (searchType === 'customtrip') {
        this.$router.push('/')
      } else {
        this.$store.commit('SET_TYPE', 'simpletrip')

        this.$store.commit('SIMPLE_TRIP_PROP', { prop: 'origin', value: budget.items[0].trip.origin })
        this.$store.commit('SIMPLE_TRIP_PROP', { prop: 'destination', value: budget.items[0].trip.destination })

        // this.$store.commit('SET_DATE', { prop: 'origin', date: budget[searchType].origin.date })
        // this.$store.commit('SET_TIME', { prop: 'origin', time: budget[searchType].origin.time })

        // this.$store.commit('SET_PLACE', {
        //   prop: 'destination',
        //   addr_info: {
        //     address: budget[searchType].destination.address,
        //     city: budget[searchType].destination.city,
        //     lat: budget[searchType].destination.lat,
        //     lng: budget[searchType].destination.lng
        //   }
        // })

        // this.$store.commit('SET_DATE', { prop: 'destination', date: budget[searchType].origin.date })
        // this.$store.commit('SET_TIME', { prop: 'destination', time: budget[searchType].origin.time })

        this.$router.push('/search-results')
      }
    },
    dateFormatter: function (date, format) {
      return this.moment(date).format(format)
    },
    getQuotePdf () {
      axios.get(process.env.API_URL + `quote-pdf`)
        .then((r) => {
        })
    },
    cleanOldQuotedItems () {
      let actualQuotesUUID = this.budgets.map(function (value) {
        return value.uuid
      })
      // for (const [key, value] of Object.entries(this.$store.state.quote.quoteList)) {
      //   if (value !== null && value.hasOwnProperty('uuid') && !actualQuotesUUID.includes(value.uuid)) {
      //     this.$store.commit('quote/removeQuoteItemList', key)
      //   } else if (value === null || value !== null && !value.hasOwnProperty('uuid')) {
      //     this.$store.commit('quote/removeQuoteItemList', key)
      //   }
      // }
    },
    // getTripStartDate() {
    //   let serviceType = this.item.type;
    //   switch (serviceType) {
    //     case 'transfer':
    //        return this.$store.state.tripInfo.transfer_date
    //       break;
    //     case 'diaria':
    //       return this.item.trip.daily.date
    //       break;
      
    //     default:
    //       return this.$store.state.origin.date
    //       break;
    //   }
    // },
    getVehicleType (type) {
      switch (type) {
        case 'onibus':
          return 'bus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro'
        case 'carro' :
          return 'car'
        default:
          return 'bus'
      }
    },
    dayBeforeTrip (budgetStartDate) {
      let actualDate = this.moment()
      let two_times_bankslip_due_days = this.$store.state.companyDetails.two_times_bankslip_due_days;
      // let tripStartDate = type == 'diaria' ? budgetStartDate : this.getTripStartDate();
      let finalDate = this.moment(budgetStartDate);
      let daysBeforeTrip = finalDate.diff(actualDate, 'days');

      this.differenceDays = daysBeforeTrip;
      this.paymentHighestDate = finalDate.subtract(two_times_bankslip_due_days, "days");
      this.paymentHighestDateFormated = finalDate.format('DD/MM/YYYY');
    },
    async getBankSlipInstallments(data) {
      await axios
        .post(process.env.API_URL + "cart/bank-slip/installments", data)
        .then((response) => {
          this.bank_slip_installments = response.data;
        })
        .catch(() => {});
    },
    quoteInfos(quoteItems , selectedQuote){
      let data = {}
      let $scope = this
      quoteItems.forEach(function(item , i){
        if(item.type == 'diaria'){
          data[i] = {
            address: item.trip.daily.addr_info.address,
            franchise_km: item.trip.daily.quote.franchise_km,
            daily_hours: item.trip.daily.quote.daily_hours,
            daily_date: item.trip.daily.date,
            daily_time: item.trip.daily.time,
            total: parseFloat(item.trip.daily.quote[$scope.getVehicleType(item.vehicle.type)])
          }
        }else if(item.type == 'transfer'){
          data[i] = {
              transfer_state: item.transferDetails.transfer_state,
              transfer_date: item.transferDetails.transfer_date,
              transfer_time: item.transferDetails.transfer_time,
              transfer_round_date: item.transferDetails.transfer_round_date,
              transfer_round_time: item.transferDetails.transfer_round_time,
          }
        }else{
          data[i] = {
            origin: item.trip.origin,
            destination: item.trip.destination
          }
        }
      })
      return data
    },
    usageVehicleType(type) {
      switch (type) {
        case 'onibus':
          return 'onibus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro_onibus'
        case 'carro' :
          return 'carro'
        default:
          return 'onibus'
      }
    },
    sumVehicleUsageTotals(quoteItems){

      let totals = 0;
      let $scope = this
      let useVehicle = ''
      let vehicleType = ''
      let factor_usage = 1
      if(quoteItems[0].trip.useVehicle){
          quoteItems.forEach(function(quote){
            vehicleType = $scope.usageVehicleType(quote.vehicle.type)
            useVehicle = quote.trip.useVehicle 
            factor_usage = quote.vehicle.factor_usage
          })
          Object.values(useVehicle).forEach(function(vehicle){
            totals += $scope.$store.state.companyDetails.vehicleUsageQuotes[vehicle.selectedIndex][vehicleType] * factor_usage
          })
      }
      return totals;
    },
    generatePayload (quoteItems , selectedQuote) {
      let infos = this.quoteInfos(quoteItems , selectedQuote)
      let actualQuote = ''
      let prices = []
      let $scope = this
      let transferDetails = ''
      let isRoundTrip = ''
      let tripOrigin = {}
      let tripDestination = {}
      selectedQuote.forEach(function(quote , index){
        actualQuote = quote[0]
        if(actualQuote.type == 'diaria'){
          actualQuote.selectedVehicle.dailyDetails = infos
          prices[index] = parseFloat(actualQuote.daily.quote[$scope.getVehicleType(actualQuote.selectedVehicle.type)])
        }else if(actualQuote.type == 'transfer'){
          actualQuote.selectedVehicle.extra.transferDetails = infos
          prices[index] = parseFloat(actualQuote.transfer[$scope.getVehicleType(actualQuote.selectedVehicle.type + '_price')])
        }else{
          actualQuote.selectedVehicle.tripInfos = infos
          isRoundTrip = actualQuote.destination.date != '' ? true : false
          prices[index] = actualQuote.total
          tripOrigin[index] = actualQuote.origin
          tripDestination[index] = actualQuote.destination
        }
      })
      let items = [
        {
          selectedVehicle: actualQuote.selectedVehicle,
          type: actualQuote.type,
          origin: actualQuote.type == 'viagem' ? tripOrigin : actualQuote.origin,
          tripDetails: actualQuote.tripDetails,
          destination: (actualQuote.type == 'viagem') ? tripDestination : (actualQuote.type == 'diaria') ? actualQuote.daily.addr_info.address : actualQuote.destination,
          is_round_trip: isRoundTrip
        }
      ]
      let total = 0;
      let payload = {
          items: Object.values(items),
          quoteExpiryDate: this.$store.state.companyDetails.quote_valid_days,
          user_id: this.$store.state.auth.userData.id,
          quoteDate: this.moment().format('DD/MM/YYYY'),
          companyDetails: {
            logo: this.$store.state.companyDetails.logo,
            phone: this.$store.state.companyDetails.phone,
            email: this.$store.state.companyDetails.email,
            address: this.$store.state.companyDetails.address,
            identification: this.$store.state.companyDetails.identification,
            whatsapp: 'api.whatsapp.com/send?phone=' + this.$store.state.companyDetails.cellphone + '&text=' + encodeURI('Olá! Gostaria de fazer um orçamento.')
          },
          paymentDetails: {
            two_times_bankslip_upfront_payment : this.$store.state.companyDetails.two_times_bankslip_upfront_payment,
            paymentHighestDate : this.paymentHighestDateFormated,
            differenceDays: this.differenceDays,
            installments : Object.keys(this.bank_slip_installments).length
          }
          
      }
      let driverExpense = []
      let pricesTotal = 0
      let driverExpenseTotal = 0
      quoteItems.forEach(function(item , i){
          if(item.type == 'diaria'){
            prices[i] = parseFloat(item.trip.daily.quote[$scope.getVehicleType(item.vehicle.type)])
          }else if(item.type == 'transfer'){
            prices[i] = parseFloat(item.transfer[$scope.getVehicleType(item.vehicle.type) + '_price'])
          }else{
            prices[i] = parseFloat(item.total)
            driverExpense[i] = item.trip.driverExpenses.driverExpensesValue
          }
      })
      switch (actualQuote.type) {
        case 'transfer':
          for(var i = 0; i < prices.length; i++){
            total += prices[i];
          }
          break
        case 'diaria':
          for(var i = 0; i < prices.length; i++){
            total += prices[i];
          }
          break
        default:
          let subtotal = 0
          for(var i = 0; i < prices.length; i++){
            subtotal += prices[i] + parseFloat(actualQuote.tripDetails.driverExpenses.driverExpensesValue)
          }
          total = subtotal + this.sumVehicleUsageTotals(quoteItems)
          payload.items[0]['selectedVehicle'] = actualQuote.selectedVehicle
          break
     }
      payload.items[0]['total'] = total
      payload['total'] = total
      payload['subtotal'] = total

      return payload
    },
    slugify (str) {
      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()
      var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
      var to = 'aaaaeeeeiiiioooouuuunc------'
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')

      return str
    },
    async print (budget) {
      let quoteList = this.$store.state.quote.quoteList
      let selectedQuote = []
      let quoteItems = {}
      let dates = []
      let budgetStartDate = this.moment()
        budget.items.forEach(function(item , index){
          selectedQuote[index] = quoteList.filter((item) => item.uuid === budget.uuid)
          quoteItems[index] = item
          if(item.type == 'diaria'){
            dates[index] = quoteItems[index].trip.daily.date
          }else if(item.type == 'viagem'){
            dates[index] = quoteItems[index].trip.origin.date
          }else{
            dates[index] = quoteItems[index].transferDetails.transfer_date
          }
        })
      dates.sort()
      budgetStartDate = dates[0] 
      
      this.$store.commit('SET_LOADING_STATE', {value: true})
      this.dayBeforeTrip(budgetStartDate)
      await this.getBankSlipInstallments({
        origin_date: this.paymentHighestDate.format('YYYY/MM/DD'),
        total: this.$store.state.quote.quote.total,
        items: this.$store.state.quote.quote.items,
      });
      let payload = this.generatePayload(Object.values(quoteItems) , Object.values(selectedQuote))

      let vehicleTitle = selectedQuote[0][0].selectedVehicle.title
      axios({
        url: process.env.API_URL + 'quote-pdf', // download url
        method: 'get',
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          mode: 'no-cors'
        },
        params: payload
      }).then(response => {
          var d = new Date()
          let hours = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear() + ' ' +
          d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
          FileSaver.saveAs(response.data, this.slugify(vehicleTitle) + '-' + this.slugify(hours))
          // this.closeModal()
          this.$store.commit('SET_LOADING_STATE', { value: false })
          this.$swal({
              title: 'Sucesso!',
              timer: 2500,
              text: 'Seu orçamento foi gerado',
              showDenyButton: false,
              showConfirmButton: false,
              iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
              icon: 'success'
            })
        })
    },
  },
  computed: {
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.primaryColor
    }
  },
  mounted () {
    this.getBudgets()
  }
}
</script>

<style>
  #thank-you {
    /* background-image: url('https://i.imgur.com/YYSwMyc.png'); */
    min-height: 762px;
  }
  .budget-list .item-order{
    padding: 15px!important;
  }

  #nav {
    margin-bottom: 0 !important;
  }

  .budget-card {
    box-shadow: 0 16px 64px -16px rgb(46 55 77 / 10%);
    background-color: white !important;
  }

  .btn-budget-delete {
    position: absolute;
    right: 10px;
    top: 15px;
    background:transparent;
    border:none;
        padding: 4px 10px;
    border: 2px solid #d8d8d8;
    border-radius: 5px;
    color: #d8d8d8;
  }

</style>
